import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    dataProyects: {
      id: null,
      name_proyect: '',
      name_official: '',
      name_estate: '',
      deep_number: '',
      official_number: '',
      image: '',
      status_id: '',
      creation_date: new Date(),
      physical_address: [],
      section_id: '', //no se para que sirve
      descrip: '',
      logo: '',
      modelDwelling: [],
      appleData: [],
      blocksData: []
    },
    appleData: {},

    blocksData: {}
  },
  mutations: {
    getInfoProyects(state, data) {
      state.dataProyects = data;
    },
    UPDATE_APPLE_DATA(state, payload) {
      state.dataProyects.appleData = {
        ...state.dataProyects.appleData,
        ...payload
      };
    },
    getInfoblocksData(state, data) {
      state.blocksData = data;
    },

  },
  actions: {
    actiongetInfoProyects({
      commit
    }, val) {
      console.log('val', val)
      commit('getInfoProyects', val);
    },
    actiongetInfoblocksData({
      commit
    }, val) {
      console.log('val', val)
      commit('getInfoblocksData', val);
    },
  },



};